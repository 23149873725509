<template>
    <b-overlay :show="loading">
        <header class="mb-3 flex justify-end">
            <b-button variant="outline-primary" @click.prevent="openMemberModal">Tambah Member</b-button>
        </header>
        <p class="text-center text-danger" v-if="!members || members.length < 1">
            <strong><i>Member paket belum ada</i></strong>
        </p>
        <section v-else>
            <b-table responsive :fields="memberFields" :items="localMembers">
                <template #cell(index)="{index}">
                    {{ ++index }}
                </template>
                <template #cell(type_member)="{item}">
                    <b-badge>{{ item.type_member }}</b-badge>
                </template>
                <template #cell(from_time)="{ item }">
                    <strong>{{ getFullTanggal(item) }}</strong>
                </template>
                <template #cell(active)="{ item }">
                    <feather-icon size="24" v-if="item.active == 1" icon="CheckIcon" class="text-success"></feather-icon>
                    <feather-icon size="24" v-else icon="XIcon" class="text-danger"></feather-icon>
                </template>
                <template #cell(action)="{item}">
                    <feather-icon size="24" icon="TrashIcon" @click.prevent="removeMember(item)" class="cursor-pointer text-danger"></feather-icon>
                </template>
            </b-table>
        </section>

        <!-- modal -->
        <b-modal title="Form Tambah Member Paket" id="member-modal" no-close-on-backdrop no-close-on-esc>
            <b-form>
                <b-form-group label="Pilih Peserta Member" class="mb-2">
                    <v-select v-model="selectedMembers" :options="userList" label="name" multiple></v-select>
                </b-form-group>
                <b-form-group label="Pilih Tanggal Mulai">
                    <b-form-input type="date" v-model="startDate"></b-form-input>
                </b-form-group>
            </b-form>

            <template #modal-footer>
                <b-button variant="primary" @click.prevent="submit">Submit</b-button>
            </template>
        </b-modal>
        <!-- /Modal -->
    </b-overlay>
</template>
<script>
import { BOverlay, BTable, BBadge, BForm, BFormGroup, BFormInput, BButton, BModal } from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
    props: ['members', 'userList'],
    components: {
        BOverlay, BTable, BBadge, BForm, BFormGroup, BFormInput, BButton, vSelect, BModal
    },
    data: () => ({
        loading: false,
        startDate: null,
        selectedMembers: [],
        memberFields: [
            { key: 'index', label: 'No' },
            { key: 'name', label: 'Nama Peserta' },
            { key: 'type_member', label: 'Jalur Daftar' },
            { key: 'from_time', label: 'Masa Berlangganan' },
            { key: 'active', label: 'Keaktifan Member' },
            { key: 'action', label: '#' }
        ],
        localMembers: []
    }),
    computed: {
        isValidForm() {
            if( !this.startDate || this.selectedMembers.length < 1 ) {
                return false
            }

            return true
        }
    },
    methods: {
        removeMember(item) {
            this.$swal({
                title: "Anda Yakin?",
                text: "Anda akan menghapus member ini?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonText: "Ya, Hapus",
                customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            })
            .then(async (result) => {
                if(result.value) {
                    const payload = {
                        id: item.id,
                        fungsi: 1
                    }
                    
                    try {
                        this.loading = true
                        const response = await this.$store.dispatch('paketTryout/storeMemberPaket', [payload])
                        this.loading = false
                        this.displaySuccess({
                            message: response.data && response.data.message ? response.data.message : 'Member paket berhasil diperbaharui'
                        })

                        setTimeout(() => window.location.reload(), 1500)
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }   
            })
        },
        async submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap lengkapi form member dengan lengkap!'
                })
                return false
            }

            const payloads = this.selectedMembers.map(user => ({
                paket_id: this.$route.params.id,
                user_id: user.id,
                from_time: this.startDate
            }))

            try {
                this.loading = true
                this.$bvModal.hide('member-modal')
                const response = await this.$store.dispatch('paketTryout/storeMemberPaket', payloads)
                
                this.displaySuccess({
                    message: response.data && response.data.message ? response.data.message : 'Member paket berhasil diperbaharui'
                })
                
                this.loading = false
                setTimeout(() => window.location.reload(), 1500)
            }
            catch(e) {
                this.$bvModal.show('member-modal')
                this.loading = false
                this.displayError(e)

            }
        },
        openMemberModal() {
            this.$bvModal.show('member-modal')
        },
        getFullTanggal( member ) {
            const fromTime = member.from_time,
                  toTime   = member.to_time
            
            if(!fromTime || !toTime) {
                return 'Waktu belum ditentukan'
            }
            
            const arrFromTime = fromTime.split(' '),
                  dateFromTime = arrFromTime[0],
                  timeFromTime = arrFromTime[1],
                  jamFromTime  = timeFromTime.split(':')

            const arrToTime = toTime.split(' '),
                  dateToTime = arrToTime[0],
                  timeToTime = arrToTime[1],
                  jamToTime  = timeToTime.split(':')

            return `${ dateFromTime.split('-').reverse().join('/') } ${jamFromTime[0]}:${jamFromTime[1]} sampai ${ dateToTime.split('-').reverse().join('/') } ${jamToTime[0]}:${jamToTime[1]}`
        }
    },
    watch: {
        members(val) {
            if(val) {
                this.localMembers = val
            }
        }
    }
}
</script>