var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-2",
      "fill": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": 'Setting Bobot Sub Kategori Paket : ' + _vm.paketDataId.name
    }
  }, [_c('b-row', [_c('b-col', [_c('div', {
    attrs: {
      "align": "right"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-sm",
      modifiers: {
        "modal-sm": true
      }
    }],
    staticClass: "btn-icon mb-2",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.ModalShow();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v("Tambah")], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "centered": "",
      "size": "sm",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.Tambah($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.tutupModal($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.Modal,
      callback: function callback($$v) {
        _vm.Modal = $$v;
      },
      expression: "Modal"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Pilih Sub Kategori",
      "label-for": "Pilih Sub Kategori"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "Subtest",
      "options": _vm.optsubcat,
      "placeholder": "Subtest"
    },
    model: {
      value: _vm.form.subcat_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subcat_id", $$v);
      },
      expression: "form.subcat_id"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Persentase Nilai",
      "label-for": "Persentase Nilai"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": "",
      "append": "%"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "Persentase Nilai",
      "placeholder": "Persentase Nilai",
      "type": "number"
    },
    model: {
      value: _vm.form.percentage,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "percentage", $$v);
      },
      expression: "form.percentage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "3",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "2",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-30",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Search......"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn,
      "fields": _vm.fields,
      "items": _vm.rasio
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(percentage)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.percentage + "%") + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalUbah(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": 'Setting Bobot Subtes per Jurusan : ' + _vm.paketDataId.name
    }
  }, [_c('b-row', [_c('b-col', [_c('div', {
    attrs: {
      "align": "right"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-xl",
      modifiers: {
        "modal-xl": true
      }
    }],
    staticClass: "btn-icon mb-2",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.ModalShowSub
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v("Tambah")], 1), _c('b-modal', {
    attrs: {
      "id": "modal-tambah-sub",
      "centered": "",
      "size": "sm",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.UbahSub($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.tutupModal($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.ModalSub,
      callback: function callback($$v) {
        _vm.ModalSub = $$v;
      },
      expression: "ModalSub"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Nilai",
      "label-for": "Nilai"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": "",
      "append": "%"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "Nilai",
      "placeholder": "Nilai",
      "type": "number"
    },
    model: {
      value: _vm.value.value,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "value", $$v);
      },
      expression: "value.value"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-Setting-sub",
      "centered": "",
      "size": "xl",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.TambahSub($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.tutupModal($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.ModalSetting,
      callback: function callback($$v) {
        _vm.ModalSetting = $$v;
      },
      expression: "ModalSetting"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "sm": "4"
    }
  }, [_c('label', [_vm._v("Pilih Subtest")]), _c('b-form-select', {
    attrs: {
      "size": "sm",
      "placeholder": "- Pilih -"
    },
    on: {
      "input": function input($event) {
        return _vm.getJurusan();
      }
    },
    model: {
      value: _vm.subtest_id,
      callback: function callback($$v) {
        _vm.subtest_id = $$v;
      },
      expression: "subtest_id"
    }
  }, [_vm._l(_vm.paketSubtestData, function (data, index) {
    return [_c('b-form-select-option-group', {
      attrs: {
        "id": data.id,
        "label": data.name
      }
    }, [_vm._l(data.items, function (data2, index) {
      return [_c('b-form-select-option', {
        attrs: {
          "id": data2.id,
          "value": data2.id
        }
      }, [_vm._v(_vm._s(data2.ujian.name) + " ")])];
    })], 2)];
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "4"
    }
  }, [_c('label', [_vm._v("Pilih Rumpun")]), _c('v-select', {
    staticClass: "select-size-sm w-full",
    attrs: {
      "label": "text",
      "clearable": true,
      "options": _vm.optRumpun,
      "placeholder": "- All -"
    },
    on: {
      "input": function input($event) {
        _vm.getJurusan();

        _vm.getMajor();
      }
    },
    model: {
      value: _vm.filterRumpun,
      callback: function callback($$v) {
        _vm.filterRumpun = $$v;
      },
      expression: "filterRumpun"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "4"
    }
  }, [_c('label', [_vm._v("Pilih Sekolah/Universitas")]), _c('v-select', {
    staticClass: "select-size-sm w-full",
    attrs: {
      "label": "text",
      "clearable": true,
      "options": _vm.optMajor,
      "placeholder": "- All -"
    },
    on: {
      "input": function input($event) {
        return _vm.getJurusan();
      }
    },
    model: {
      value: _vm.filterMajor,
      callback: function callback($$v) {
        _vm.filterMajor = $$v;
      },
      expression: "filterMajor"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "3",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect3",
      "size": "sm",
      "options": _vm.pageOptions3
    },
    model: {
      value: _vm.perPage3,
      callback: function callback($$v) {
        _vm.perPage3 = $$v;
      },
      expression: "perPage3"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "2",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect3"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "sortBySelect3",
      "options": _vm.sortOptions3
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy3,
      callback: function callback($$v) {
        _vm.sortBy3 = $$v;
      },
      expression: "sortBy3"
    }
  }), _c('b-form-select', {
    staticClass: "w-30",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc3,
      callback: function callback($$v) {
        _vm.sortDesc3 = $$v;
      },
      expression: "sortDesc3"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput3",
      "type": "search",
      "placeholder": "Search......"
    },
    model: {
      value: _vm.filter3,
      callback: function callback($$v) {
        _vm.filter3 = $$v;
      },
      expression: "filter3"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter3
    },
    on: {
      "click": function click($event) {
        _vm.filter3 = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.loaded ? _c('b-table', {
    attrs: {
      "responsive": "",
      "striped": "",
      "per-page": _vm.perPage3,
      "current-page": _vm.currentPage3,
      "sort-by": _vm.sortBy3,
      "sort-desc": _vm.sortDesc3,
      "sort-direction": _vm.sortDirection3,
      "filter": _vm.filter3,
      "filter-included-fields": _vm.filterOn3,
      "fields": _vm.fields3,
      "items": _vm.JurusanData
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy3 = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy3 = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc3 = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc3 = $event;
      },
      "filtered": _vm.onFiltered3
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(selected)",
      fn: function fn(_ref) {
        var rowSelected = _ref.rowSelected;
        return [rowSelected ? [_c('i', {
          staticClass: "feather icon-disc primary"
        })] : [_c('i', {
          staticClass: "feather icon-circle"
        })]];
      }
    }, {
      key: "cell(tgl)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDate(data.item.info_start)) + " s/d " + _vm._s(_vm.humanDate(data.item.info_end)) + " ")];
      }
    }, {
      key: "cell(check)",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": data.item
          },
          model: {
            value: _vm.select_soal,
            callback: function callback($$v) {
              _vm.select_soal = $$v;
            },
            expression: "select_soal"
          }
        })];
      }
    }, {
      key: "cell(max_quota)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(data.item.max_quota)) + " ")];
      }
    }, {
      key: "cell(color)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": data.item.color
          }
        }, [_vm._v(" " + _vm._s(data.item.color) + " ")])];
      }
    }, {
      key: "cell(value)",
      fn: function fn(data) {
        return [_c('b-form-input', {
          attrs: {
            "size": "sm",
            "placeholder": "nilai",
            "type": "number"
          },
          model: {
            value: data.item.value,
            callback: function callback($$v) {
              _vm.$set(data.item, "value", $$v);
            },
            expression: "data.item.value"
          }
        })];
      }
    }], null, false, 3810146504),
    model: {
      value: _vm.setting,
      callback: function callback($$v) {
        _vm.setting = $$v;
      },
      expression: "setting"
    }
  }) : _vm._e(), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows3,
      "per-page": _vm.perPage3,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage3,
      callback: function callback($$v) {
        _vm.currentPage3 = $$v;
      },
      expression: "currentPage3"
    }
  })], 1)], 1)], 1), _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage2,
      "current-page": _vm.currentPage2,
      "sort-by": _vm.sortBy2,
      "sort-desc": _vm.sortDesc2,
      "sort-direction": _vm.sortDirection2,
      "filter": _vm.filter2,
      "filter-included-fields": _vm.filterOn2,
      "fields": _vm.fields2,
      "items": _vm.utbksubtest
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy2 = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy2 = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc2 = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc2 = $event;
      },
      "filtered": _vm.onFiltered2
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalUbahSub(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapusSub(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1)], 1)];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows2,
      "per-page": _vm.perPage2,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage2,
      callback: function callback($$v) {
        _vm.currentPage2 = $$v;
      },
      expression: "currentPage2"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }