<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <b-card-body>
          <b-tabs content-class="mt-2" fill>
            <b-tab :title="'Siswa di bintara : ' + paketDataId.name">
              <b-row>
                <b-col>
                  <!-- <div align="right">
                    <b-button
                      variant="outline-primary"
                      v-b-modal.modal-sm
                      @click="ModalShow()"
                      class="btn-icon mb-2"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                    >
                  </div> -->
                  <b-modal
                    v-model="Modal"
                    id="modal-tambah"
                    centered
                    size="sm"
                    :title="titleModal"
                  >
                    <b-card-text>
                      <b-form>
                        <b-form-group
                          label="Pilih Sub Kategori"
                          label-for="Pilih Sub Kategori"
                        >
                          <b-form-select
                            id="Subtest"
                            v-model="form.subcat_id"
                            :options="optsubcat"
                            placeholder="Subtest"
                          />
                        </b-form-group>
                        <b-form-group
                          label="Persentase Nilai"
                          label-for="Persentase Nilai"
                        >
                          <b-input-group prepend="" append="" class="input-group-merge">
                            <b-form-input
                              id="Persentase Nilai"
                              v-model="form.percentage"
                              placeholder="Persentase Nilai"
                              type="number"
                            />
                          </b-input-group>
                        </b-form-group>
                      </b-form>
                    </b-card-text>

                    <template #modal-footer>
                      <div class="w-100">
                        <p class="float-left mb-0"></p>

                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          variant="outline-success"
                          class="mr-1 btn-icon"
                          @click.prevent="Tambah"
                        >
                          <feather-icon icon="SaveIcon" class="mr-25" />{{
                            label || "Tambah"
                          }}
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-primary"
                          class="float-right btn-icon"
                          @click.prevent="tutupModal"
                        >
                          <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                        </b-button>
                      </div>
                    </template>
                  </b-modal>
                </b-col>
              </b-row>
              <b-card-text class="blog-content-truncate">
                <b-row>
                  <b-col md="3" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                      <label class="d-inline-block text-sm-left mr-50">Per page</label>
                      <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="w-50"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" sm="8" class="my-1">
                    <b-form-group
                      label="Sort"
                      label-cols-sm="2"
                      label-align-sm="right"
                      label-size="sm"
                      label-for="sortBySelect"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-select
                          id="sortBySelect"
                          v-model="sortBy"
                          :options="sortOptions"
                          class="w-50"
                        >
                          <template v-slot:first>
                            <option value="">-- none --</option>
                          </template>
                        </b-form-select>
                        <b-form-select
                          v-model="sortDesc"
                          size="sm"
                          :disabled="!sortBy"
                          class="w-30"
                        >
                          <option :value="false">Asc</option>
                          <option :value="true">Desc</option>
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="5" class="my-1">
                    <b-form-group label-for="filterInput" class="mb-0">
                      <b-input-group size="sm">
                        <b-form-input
                          id="filterInput"
                          v-model="filter"
                          type="search"
                          placeholder="Search......"
                        />
                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-table
                      striped
                      small
                      hover
                      responsive
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      :fields="fields"
                      :items="rasio"
                    >
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <!-- <template #cell(percentage)="data">
                        {{ data.item.percentage + "%" }}
                      </template> -->
                      <!-- <template #cell(action)="row">
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25"
                            />
                          </template>
                          <b-dropdown-item @click="ModalUbah(row.item)">
                            <feather-icon icon="Edit2Icon" class="mr-50" />
                            <span>Edit</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="ModalHapus(row.item)">
                            <feather-icon icon="TrashIcon" class="mr-50" />
                            <span>Hapus</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </template> -->
                    </b-table>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-tab>
            <b-tab :title="'Setting Bobot Subtes Psikotes : ' + paketDataId.name">
              <b-row>
                <!-- <b-col>
                  <div>Setting Bobot Subtes per Jurusan : {{ paketDataId.name }}</div>
                </b-col> -->
                <b-col>
                  <div align="right">
                    <b-button
                      variant="outline-primary"
                      v-b-modal.modal-xl
                      @click="ModalShowSub"
                      class="btn-icon mb-2"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                    >
                    <b-modal
                      v-model="ModalSub"
                      id="modal-tambah-sub"
                      centered
                      size="sm"
                      :title="titleModal"
                    >
                      <b-card-text>
                        <b-form>
                          <b-form-group label="Nilai" label-for="Nilai">
                            <b-input-group prepend="" append="" class="input-group-merge">
                              <b-form-input
                                id="Nilai"
                                v-model="value.value"
                                placeholder="Nilai"
                                type="number"
                              />
                            </b-input-group>
                          </b-form-group>
                        </b-form>
                      </b-card-text>

                      <template #modal-footer>
                        <div class="w-100">
                          <p class="float-left mb-0"></p>

                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="outline-success"
                            class="mr-1 btn-icon"
                            @click.prevent="UbahSub"
                          >
                            <feather-icon icon="SaveIcon" class="mr-25" />{{
                              label || "Tambah"
                            }}
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            class="float-right btn-icon"
                            @click.prevent="tutupModal"
                          >
                            <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                          </b-button>
                        </div>
                      </template>
                    </b-modal>
                  </div>
                  <!-- SUB -->
                </b-col>
              </b-row>

              <b-modal
                v-model="ModalSetting"
                id="modal-Setting-sub"
                centered
                size="xl"
                :title="titleModal"
              >
                <b-row>
                  <b-col md="4" sm="4" class="">
                    <label>Pilih Kategori Subtest</label>
                    <b-form-select
                      v-model="subtest_id"
                      size="sm"
                      placeholder="- Pilih -"
                      :options="optpaketSubtestData"
                      @input="getSub()"
                    >
                      <!-- <template v-for="(data, index) in paketSubtestData">
                        <b-form-select-option-group :id="data.id" :label="data.name">
                          <template v-for="(data2, index) in data.items">
                            <b-form-select-option :id="data2.id" :value="data2.id"
                              >{{ data2.ujian.name }}
                            </b-form-select-option>
                          </template>
                        </b-form-select-option-group>
                      </template> -->
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                      <label class="d-inline-block text-sm-left mr-50">Per page</label>
                      <b-form-select
                        id="perPageSelect3"
                        v-model="perPage3"
                        size="sm"
                        :options="pageOptions3"
                        class="w-50"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" sm="8" class="my-1">
                    <b-form-group
                      label="Sort"
                      label-cols-sm="2"
                      label-align-sm="right"
                      label-size="sm"
                      label-for="sortBySelect3"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-select
                          id="sortBySelect3"
                          v-model="sortBy3"
                          :options="sortOptions3"
                          class="w-50"
                        >
                          <template v-slot:first>
                            <option value="">-- none --</option>
                          </template>
                        </b-form-select>
                        <b-form-select
                          v-model="sortDesc3"
                          size="sm"
                          :disabled="!sortBy"
                          class="w-30"
                        >
                          <option :value="false">Asc</option>
                          <option :value="true">Desc</option>
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="5" class="my-1">
                    <b-form-group label-for="filterInput" class="mb-0">
                      <b-input-group size="sm">
                        <b-form-input
                          id="filterInput3"
                          v-model="filter3"
                          type="search"
                          placeholder="Search......"
                        />
                        <b-input-group-append>
                          <b-button :disabled="!filter3" @click="filter3 = ''">
                            Clear
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" v-if="subtest_id">
                    <b-table
                      responsive
                      striped
                      :per-page="perPage3"
                      :current-page="currentPage3"
                      :sort-by.sync="sortBy3"
                      :sort-desc.sync="sortDesc3"
                      :sort-direction="sortDirection3"
                      :filter="filter3"
                      :filter-included-fields="filterOn3"
                      @filtered="onFiltered3"
                      :fields="fields3"
                      :items="paketSubtestData"
                      v-model="setting"
                    >
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                          <i class="feather icon-disc primary" />
                        </template>

                        <template v-else>
                          <i class="feather icon-circle" />
                        </template>
                      </template>

                      <template #cell(tgl)="data">
                        {{ humanDate(data.item.info_start) }} s/d
                        {{ humanDate(data.item.info_end) }}
                      </template>

                      <template #cell(check)="data">
                        <b-form-checkbox :value="data.item" v-model="select_soal" />
                      </template>
                      <template #cell(max_quota)="data">
                        {{ formatRupiah(data.item.max_quota) }}
                      </template>

                      <template #cell(color)="data">
                        <b-badge :variant="data.item.color">
                          {{ data.item.color }}
                        </b-badge>
                      </template>
                      <template #cell(value)="data">
                        <b-form-input
                          v-model="data.item.value"
                          size="sm"
                          placeholder="nilai"
                          type="number"
                        />
                      </template>
                    </b-table>

                    <b-pagination
                      v-model="currentPage3"
                      :total-rows="totalRows3"
                      :per-page="perPage3"
                      align="center"
                      size="sm"
                      class="my-0"
                    />
                  </b-col>
                  <b-col cols="12" v-else>
                    <b-card class="border text-center text-primary">
                      Silahkan pilih kategori subtest terlebih dahulu!!
                    </b-card>
                  </b-col>
                </b-row>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="TambahSub"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click.prevent="tutupModal"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage2"
                :current-page="currentPage2"
                :sort-by.sync="sortBy2"
                :sort-desc.sync="sortDesc2"
                :sort-direction="sortDirection2"
                :filter="filter2"
                :filter-included-fields="filterOn2"
                @filtered="onFiltered2"
                :fields="fields2"
                :items="databobotbintara"
              >
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(action)="row">
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="ModalUbahSub(row.item)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="ModalHapusSub(row.item)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>

              <b-pagination
                v-model="currentPage2"
                :total-rows="totalRows2"
                :per-page="perPage2"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BTabs,
  BTab,
  BDropdown,
  BDropdownDivider,
  BDropdownForm,
  BDropdownGroup,
  BDropdownItem,
  BTableLite,
  BMedia,
  BImg,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormSelectOptionGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["paketDataId"],
  components: {
    BTabs,
    BTab,
    BDropdown,
    BDropdownDivider,
    BDropdownForm,
    BDropdownGroup,
    BDropdownItem,
    vSelect,
    BTableLite,
    BMedia,
    BImg,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormSelectOptionGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    BInputGroupPrepend,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loaded: false,
      optUjian: [],
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      rasio: [],
      optsubcat: [],
      optpaketSubtestData: [],
      paketSubtestData: [],
      paketSubtestDataItems: [],
      databobotbintara: [],
      Datasubtest: [],
      filterRumpun: null,
      filterMajor: null,
      // filterJurusan: null,
      // filterProv: null,
      // optProv: [],
      // optJurusan: [],
      optRumpun: [],
      optMajor: [],
      // paketDataId: {
      //   name: null,
      // },
      select_soal: [],
      setting: null,
      subtest_id: null,
      form: {
        id: null,
        name: null,
        paket_id: null,
      },
      formSub: {
        id: null,
        // title: null,
        ujian_id: null,
        subcat_id: null,
        // timer: null,
        // sum_soal: null,
      },
      value: {},
      //Modal
      titleModal: null,
      Modal: false,
      ModalSub: false,
      ModalSetting: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "user.name", label: "Nama" },
        { key: "user.email", label: "Email" },
        { key: "school.name", label: "Sekolah", sortable: true },
        { key: "action", label: "#" },
      ],
      fields2: [
        { key: "index", label: "No" },
        { key: "subtest.ujian.name", label: "Subtest" },
        { key: "value", label: "Nilai", sortable: true },
        { key: "action", label: "#" },
      ],
      fields3: [
        { key: "index", label: "No" },
        { key: "check", label: "Pilih", sortable: true },
        { key: "nama_ujian", label: "Subtest", sortable: true },
        { key: "value", label: "Nilai Bobot", sortable: true },
        { key: "show_details", label: "#" },
        // { key: "action", label: "#" },
      ],
      fieldsItems: [
        // { key: "index", label: "No" },
        { key: "title", label: "Subtest", sortable: true },
        // { key: "timer", label: "Waktu", sortable: true },
        // { key: "sum_soal", label: "Jumlah Soal", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "text", value: "Subtest" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Subtest Paket",
      search: null,
      idd: null,
      optFilter2: [
        { id: "text", value: "Subtest" },
        // { id: "nip", value: "NIP" },
      ],
      perPage2: 10,
      pageOptions2: [10, 30, 50],
      totalRows2: 1,
      currentPage2: 1,
      sortBy2: "",
      sortDesc2: false,
      sortDirection2: "asc",
      filter2: null,
      filterOn2: [],
      optFilter3: [
        { id: "text", value: "Subtest" },
        // { id: "nip", value: "NIP" },
      ],
      perPage3: 10,
      pageOptions3: [10, 30, 50],
      totalRows3: 1,
      currentPage3: 1,
      sortBy3: "",
      sortDesc3: false,
      sortDirection3: "asc",
      filter3: null,
      filterOn3: [],
      submitwe: [],
    };
  },
  watch: {
    // select_soal(array) {
    //   if (array && array.length > 0) {
    //     let id_soal = array.map((item) => item.id);
    //     let cek1 = {
    //       // item.id;
    //       major_id : id_soal,
    //       paket_id : this.$route.params.id,
    //       subtest_id : this.subtest_id,
    //       value : item.value,
    //     };
    //     // let judul_soal = array.map((item) => item.id);
    //     // this.ujianDataId.soal_ids = id_soal.join(",");
    //     this.submitwe = cek1;
    //   }
    // },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fields2
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions3() {
      // Create an options list from our fields
      return this.fields3
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems1) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems1.length;
      this.currentPage = 1;
    },
    onFiltered2(filteredItems2) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems2.length;
      this.currentPage2 = 1;
    },
    onFiltered3(filteredItems3) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows3 = filteredItems3.length;
      this.currentPage3 = 1;
    },
    tutupModal() {
      this.Modal = false;
      this.ModalSub = false;
      this.ModalSetting = false;
    },
    resetForm() {
      this.form = {
        id: null,
        text: null,
        paket_id: null,
      };
      this.formSub = {
        id: null,
        title: null,
        paket_id: null,
        parent_id: null,
        timer: null,
        sum_soal: null,
      };
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Subtest Paket";
      this.Modal = true;
      // this.getSubtest();
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Subtest Paket";
      this.Modal = true;
      // this.getSubtest();
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const item = {
            id: data.id,
            fungsi: 1,
          };
          // data.fungsi = 1; //soft delete
          this.$store
            .dispatch("rasionalisasi/CUDbintara", [item])
            .then(() => {
              this.getSetting();
              this.getJurusan();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "AlertCircleIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      // if (this.form.name == null || this.form.name == "") {
      //   this.pesanGagal();
      //   return false;
      // }
      let payload = {
        subcat_id: this.form.subcat_id,
        percentage: this.form.percentage,
        paket_id: this.$route.params.id,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("rasionalisasi/CUDutbksubcat", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          // this.ModalEdit = false;
          this.getSubcat();
          this.displaySuccess({
            text: "Subtest Paket berhasil di" + this.activeAction,
          });
          this.resetForm();
          this.Modal = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },

    ModalShowSub() {
      this.activeAction = "tambah";
      this.titleModal = "Tambah Setting Bobot Subtes Psikotes";
      this.ModalSetting = true;
    },
    ModalUbahSub(data) {
      // this.getSubSubtest();
      // this.getDataUjian();
      // this.getRumpun();
      this.id = data.id;
      this.value = data;
      // this.value = data.value;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Subtest";
      this.ModalSub = true;
      // this.getSubtest();
      // this.ModalEdit = true;
    },
    ModalHapusSub(dataSub) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const sub = {
            id: dataSub.id,
            fungsi: 1, //soft delete
          };
          this.$store
            .dispatch("rasionalisasi/CUDbintara", [sub])
            .then(() => {
              this.getSetting();
              this.getJurusan();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    TambahSub() {
      if (this.subtest_id == null || this.subtest_id == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        paket_id: this.$route.params.id,
        subtest_id: this.select_soal.id,
        value: this.select_soal.value,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("rasionalisasi/CUDbintara", this.select_soal)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          // this.ModalEdit = false;
          this.subtest_id = null;

          this.ModalSub = false;
          this.ModalSetting = false;
          this.getSetting();
          this.displaySuccess({
            text: "Subtest Paket berhasil di" + this.activeAction,
          });
          this.resetForm();
          this.ModalSub = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    UbahSub() {
      if (this.value == null || this.value == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        paket_id: this.$route.params.id,
        subtest_id: this.value.subtest_id,
        value: this.value.value,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("rasionalisasi/CUDbintara", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          // this.ModalEdit = false;
          this.getSetting();
          this.ModalSub = false;
          this.ModalSetting = false;
          this.displaySuccess({
            text: "Subtest Paket berhasil di" + this.activeAction,
          });
          this.resetForm();
          this.ModalSub = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getDataUjian() {
      this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        category_paket_id: this.paketDataId.category.id,
        mapel_id: this.filterMapel != null ? this.filterMapel.id : null,
        type_ujian: this.filterTipe != null ? this.filterTipe.id : null,
        user_id: this.user.id,
      };
      this.$store
        .dispatch("adminujian/index", payload)
        .then((response) => {
          this.loading = false;
          let ujiandata = response.data.data;
          this.ujiandata = ujiandata;
          ujiandata.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optUjian = ujiandata;

          // this.totalRows = this.ujiandata.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getSubtest() {
      this.loaded = false;
      this.loading = true;
      const params = {
        paket_id: this.$route.params.id,
      };
      this.$store
        .dispatch("adminujian/subtesPaket", params)
        .then((response) => {
          let cek = response.data.data;
          cek.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optpaketSubtestData = cek;

          this.loading = false;
          this.loaded = true;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getSiswaBintara() {
      const payload = {
        paket_id: this.$route.params.id,
      };
      this.$store
        .dispatch("rasionalisasi/indexsiswabintara", payload)
        .then((response) => {
          let rasio = response.data.data;
          this.rasio = rasio;
          this.totalRows = this.rasio.length;
          // this.paketSubtestDataItems = paketSubtestData.items;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getSetting() {
      const params = {
        paket_id: this.$route.params.id,
      };
      this.$store
        .dispatch("rasionalisasi/indexbintara", params)
        .then((response) => {
          let databobotbintara = response.data.data;
          this.databobotbintara = databobotbintara;
          this.totalRows2 = this.databobotbintara.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getDataPaketSubtest() {
      const params = {
        paket_id: this.$route.params.id,
      };
      this.$store
        .dispatch("adminujian/subtesPaket", params)
        .then((response) => {
          let Datasubtest = response.data.data;
          this.Datasubtest = Datasubtest;
          this.totalRows = this.Datasubtest.length;
          // this.DatasubtestItems = Datasubtest.items;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },

    async getSub() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // paket_id: this.$route.params.id,
        // rumpun_id: this.filterRumpun != null ? this.filterRumpun.value : null,
        paket_id: this.$route.params.id,
        subcat_id: this.subtest_id != null ? this.subtest_id : null,
      };
      this.$store
        .dispatch("adminujian/subtesPaket", payload)
        .then((response) => {
          let cek = response.data.data[0].items;
          
          let idSubtest = cek.map((item) => item.id);
          let idSetting = this.databobotbintara.map((item) => item.subtest.id);
          var filtered = idSubtest.filter(function (e) {
            return this.indexOf(e) < 0;
          }, idSetting);
          var filterMajor = cek.filter(function (item) {
            return filtered.indexOf(item.id) !== -1;
          });

          this.paketSubtestData = filterMajor;
          const payloadUjian = this.paketSubtestData.map((ujian) => {
            return ujian.id >= 0
              ? {
                  nama_ujian: ujian.nama_ujian,
                  subtest_id: ujian.id,
                  paket_id: this.$route.params.id,
                  value: null,
                }
              : ujian;
          });
          this.paketSubtestData = payloadUjian;
          this.totalRows3 = this.paketSubtestData.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getJurusan() {
      this.loaded = false;
      this.loading = true;
      const payload = {
        rumpun_id: this.filterRumpun != null ? this.filterRumpun.value : null,
        school_id: this.filterMajor != null ? this.filterMajor.value : null,
      };
      try {
        const response = await this.$store.dispatch("jurusan/index", payload);
        const optJurusan = response.data.data;
        let idJurusan = optJurusan.map((item) => item.id);
        const cari = this.databobotbintara;
        const filterSubtest = cari.filter(
          (teang) => teang.subtest_id === this.subtest_id
        );

        let idMajor = filterSubtest.map((item) => item.major_id);

        var filtered = idJurusan.filter(function (e) {
          return this.indexOf(e) < 0;
        }, idMajor);

        var filterMajor = optJurusan.filter(function (item) {
          return filtered.indexOf(item.id) !== -1;
        });

        this.JurusanData = filterMajor;
        const payloadUjian = this.JurusanData.map((ujian) => {
          return ujian.id >= 0
            ? {
                // ...ujian,
                rumpun: {
                  name: ujian.rumpun.name,
                },
                school: {
                  name: ujian.school.name,
                },
                name: ujian.name,
                major_id: ujian.id,
                subtest_id: this.subtest_id,
                paket_id: this.$route.params.id,
                value: null,
              }
            : ujian;
        });
        this.JurusanData = payloadUjian;

        this.totalRows3 = this.JurusanData.length;
        this.loaded = true;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.error(e);
        // this.$root.$emit("errorData", error);
      }
    },
    async getRumpun() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // paket_id: this.$route.params.id,
        // rumpun_id: this.filterRumpun != null ? this.filterRumpun.value : null,
        // school_id: this.filterMajor != null ? this.filterMajor.value : null,
      };
      this.$store
        .dispatch("rumpun/index", payload)
        .then((response) => {
          let optRumpun = response.data.data;
          // optRumpun.unshift({ id: null, name: "- Pilih Jurusan -" });
          optRumpun.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optRumpun = optRumpun;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getMajor() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // paket_id: this.$route.params.id,
        is_official_school: 0,
        rumpun_id: this.filterRumpun != null ? this.filterRumpun.value : null,
        // school_id: this.filterMajor != null ? this.filterMajor.value : null,
      };
      this.$store
        .dispatch("masterUniv/index", payload)
        .then((response) => {
          let optMajor = response.data.data;
          // optMajor.unshift({ id: null, name: "- Pilih Major -" });
          optMajor.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optMajor = optMajor;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {},
  mounted() {
    this.getSubtest();
    // this.getRumpun();
    // this.getSiswaBintara();
    this.getSetting();
  },
};
</script>

<style></style>
