var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_c('b-row', [_c('b-col', [_c('div', [_vm._v("Subtest Paket : " + _vm._s(_vm.paket.name))])]), _c('b-col', [_c('div', {
    attrs: {
      "align": "right"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-sm",
      modifiers: {
        "modal-sm": true
      }
    }],
    staticClass: "btn-icon",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        _vm.paketHasSubCategory ? _vm.ModalShow() : _vm.ModalShowSub();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(_vm._s(_vm.paketHasSubCategory ? 'Tambah Sub Kategori' : 'Tambah Subtest'))], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "centered": "",
      "size": "sm",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.Tambah($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.tutupModal($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.Modal,
      callback: function callback($$v) {
        _vm.Modal = $$v;
      },
      expression: "Modal"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Nama Sub Kategori Paket",
      "label-for": "subcat"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "subcat",
      "placeholder": "Ex: Tes Skolastik"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-tambah-sub",
      "centered": "",
      "size": "sm",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.TambahSub($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.tutupModal($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.ModalSub,
      callback: function callback($$v) {
        _vm.ModalSub = $$v;
      },
      expression: "ModalSub"
    }
  }, [_c('b-card-text', [_c('b-form', [_vm.paketHasSubCategory ? _c('b-form-group', {
    attrs: {
      "label": "Sub Kategori",
      "label-for": "Subtest"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "Subtest",
      "options": _vm.paketSubtestDataParent,
      "placeholder": "Sub Kategori"
    },
    model: {
      value: _vm.formSub.subcat_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formSub, "subcat_id", $$v);
      },
      expression: "formSub.subcat_id"
    }
  })], 1) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "Pilih Ujian",
      "label-for": "ujian"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "ujian",
      "options": _vm.optUjian,
      "placeholder": "Ujian"
    },
    model: {
      value: _vm.formSub.ujian_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formSub, "ujian_id", $$v);
      },
      expression: "formSub.ujian_id"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card-text', {
    staticClass: "blog-content-truncate"
  }, [_vm.paketHasSubCategory ? _c('section', _vm._l(_vm.paketSubtestData, function (paket) {
    return _c('app-collapse', {
      key: paket.id,
      attrs: {
        "accordion": "",
        "type": "border"
      }
    }, [_c('app-collapse-item', {
      attrs: {
        "title": paket.name + ' (' + paket.items.length + ')'
      }
    }, [_c('div', {
      attrs: {
        "align": "right"
      }
    }, [_c('b-dropdown', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "mb-2",
      attrs: {
        "id": "dropdown-1",
        "text": "Aksi",
        "variant": "primary"
      }
    }, [_c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.modal-lg",
        modifiers: {
          "modal-lg": true
        }
      }],
      on: {
        "click": function click($event) {
          return _vm.ModalShowSub(paket);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "PlusIcon"
      }
    }), _c('span', [_vm._v("Tambah Subtest")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function click($event) {
          return _vm.ModalUbah(paket);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "EditIcon"
      }
    }), _c('span', [_vm._v("Edit Sub Kategori")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function click($event) {
          return _vm.ModalHapus(paket);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "TrashIcon"
      }
    }), _c('span', [_vm._v("Hapus Sub Kategori")])], 1)], 1)], 1), paket.items.length > 0 ? _c('div', [_c('b-table-lite', {
      attrs: {
        "responsive": "",
        "fields": _vm.fieldsItems,
        "items": paket.items
      },
      scopedSlots: _vm._u([{
        key: "cell(title)",
        fn: function fn(data) {
          return [_c('h5', {
            staticClass: "mt-0"
          }, [_c('b', [_vm._v(_vm._s(data.item.nama_ujian) + " ")])]), _c('p', {
            staticClass: "mb-2"
          }, [_c('b-badge', {
            staticClass: "ml-25",
            attrs: {
              "variant": "light-secondary"
            }
          }, [_c('feather-icon', {
            staticClass: "mr-25",
            attrs: {
              "icon": "ClipboardIcon"
            }
          }), _c('span', {
            staticClass: "ml-25"
          }, [_vm._v(_vm._s(data.item.sum_soal) + " Soal")])], 1)], 1)];
        }
      }, {
        key: "cell(action)",
        fn: function fn(row) {
          return [_c('b-dropdown', {
            attrs: {
              "variant": "link",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('feather-icon', {
                  staticClass: "text-body align-middle mr-25",
                  attrs: {
                    "icon": "MoreVerticalIcon",
                    "size": "16"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "to": {
                name: 'admin-paket-ujian-detail',
                query: {
                  ujian_id: row.item.ujian_id
                }
              }
            }
          }, [_c('feather-icon', {
            staticClass: "mr-50",
            attrs: {
              "icon": "SettingsIcon"
            }
          }), _c('span', [_vm._v("Buat & Pilih Soal")])], 1), _c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.ModalUbahSub(row.item);
              }
            }
          }, [_c('feather-icon', {
            staticClass: "mr-50",
            attrs: {
              "icon": "Edit2Icon"
            }
          }), _c('span', [_vm._v("Edit")])], 1), _c('b-dropdown-item', {
            on: {
              "click": function click($event) {
                return _vm.ModalHapusSub(row.item);
              }
            }
          }, [_c('feather-icon', {
            staticClass: "mr-50",
            attrs: {
              "icon": "TrashIcon"
            }
          }), _c('span', [_vm._v("Hapus")])], 1)], 1)];
        }
      }, {
        key: "cell()",
        fn: function fn(data) {
          return [_vm._v(" " + _vm._s(data.value) + " ")];
        }
      }], null, true)
    })], 1) : _c('div', [_c('p', [_vm._v("Tidak ada data")])])])], 1);
  }), 1) : _c('section', [_c('div', {
    staticClass: "flex justify-center mb-3"
  }), _vm.paketSubtestData.length > 0 ? _c('b-table-lite', {
    attrs: {
      "responsive": "",
      "fields": _vm.subtestField,
      "items": _vm.paketSubtestData
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(++index) + " ")];
      }
    }, {
      key: "cell(title)",
      fn: function fn(data) {
        return [_c('strong', [_c('h5', {
          staticClass: "mt-0"
        }, [_vm._v(" " + _vm._s(data.item.nama_ujian) + " ")])])];
      }
    }, {
      key: "cell(sum_soal)",
      fn: function fn(row) {
        return [_c('p', [_c('span', [_vm._v(_vm._s(row.item.sum_soal) + " Soal")])])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalUbahSub(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapusSub(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1)], 1)];
      }
    }, {
      key: "cell()",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.value) + " ")];
      }
    }], null, false, 1994435632)
  }) : _c('p', [_c('strong', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v("Data Subtes kosong, harap buat.")])])])], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }